
.button {
  margin-left: 100px;
}

.table-facts {
  border: 1px solid black;
  textAlign: left;
}

.td-base {
  padding: 10px;
  border: none;
  cursor: pointer;
  vertical-align: top;
  textAlign: center;
}

.td-plan {
  padding: 10px;
  border: 1px solid black;
  cursor: pointer;
  vertical-align: top;
  textAlign: center;
  background-color: #ffffff;
}

.td-plan-selected {
  padding: 10px;
  border: 1px solid black;
  cursor: pointer;
  vertical-align: top;
  textAlign: center;
  background-color: #f0f0f0;
}

.form-container {
  display: flex;
  align-items: center;
}

.form-label {
  margin-right: 10px;
}

.form-input {
  flex: 1;
}

.single-fact-overlay {
  position: fixed;
  top: 100;
  left: 100;
  width: 1000px;
  height: 250px;
  display: flex;
  background: #ADD8E6;
  zIndex: 1;
  overflow: auto;
}