.conversation-container {
    width: 100%;
    max-width: 500px;
    min-width: 400px;
    height: 100vh;
    margin: 0 auto;
    padding: 20px;
    overflow: hidden;
    border-radius: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.message-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    overflow-y: auto;
}

.message-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    &.user{
        align-items: flex-end;
    }
    &.elliq{
        align-items: flex-start;
    }
    &.system{
        align-items: center;
    }
    .timestamp {
        font-size: 12px;
        color: #666;
    }
}

.message {
    position: relative;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;
    font-size: 16px;
    max-width: 90%;
}

.message .close {
    position: absolute;
    top: 0;
    right: 7px;
    cursor: pointer;
}

.no-link {
    text-decoration: unset;
    color: unset;
}

.user-message {
    background-color: #d1f1ff;
    align-self: flex-end;
}

.elliq-message {
    background-color: #f5c33b1c;
    align-self: flex-start;
}

.system-message {
    background-color: #f2f2f2;
    align-self: center;
    font-style: italic;
    color: #666;
}

.plan-message {
    background-color: #f5c33b66;
    align-self: center;
    font-style: italic;
}

.translated-text {
    font-style: italic;
    color: #666;
}

.extra-params {
    font-size: 12px;
    color: #666;
}