//@import "fonts";

#app {
  width: 100%;
  height: 100%;
}

//body {
//  margin: 0;
//  overflow: hidden;
//}
html,
body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
}


div {
  font-family: 'font-light', sans-serif
}

img {
  display: inline-block;
}

input {
  border: none;
  font-size: 16px;
  //border-radius: 40px;
}

input:focus {
  outline-width: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea:focus {
  outline-width: 0;
}

a {
  color: blue;
}

canvas.drawingBuffer {
  display: none;
}

.form-error-message-on {
  color: red;
  text-align: center;
}

.hidden {
  display: none;
}

/**
 * Flex shit
 */
.ll_v_l {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
}

.ll_v_c {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.ll_v_r {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
}

.ll_h_t {
  display: flex;
  flex-flow: row;
  align-items: flex-start;
}

.ll_h_c {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.space-between {
  justify-content: space-between;
}

.ll_h_r {
  display: flex;
  flex-flow: row;
  align-items: flex-end;
}

/**
 * Dimension shit
 */
.match_height {
  height: 100%
}

.match_width {
  width: 100%
}

.center_h {
  margin-left: auto;
  margin-right: auto;
}

.center_v {
  margin-top: auto;
  margin-bottom: auto;
}

.center {
  margin: 0 auto;
}

.clickable {
  cursor: pointer
}


.full_screen_overlay {
  position: absolute;
  z-index: 2;
}

.full_screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.absolute {
  position: absolute;
}

.right {
  right: 0;
}

.bottom {
  bottom: 0;
}

.left {
  left: 0;
}

.top {
  top: 0;
}

.full_screen_overlay {
  position: absolute;
  z-index: 2;
}

.full_screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.absolute {
  position: absolute;
}

/*keep autocomplete on top */
.pac-container {
  z-index: 33333 !important;
}

/*phone camera buttons */
.qrcode-text-btn {
  display: inline-block;
  height: 4rem;
  width: 4rem;
  border-radius: 0.5em;
  background-color: buttonface;
  border: 0.1em solid #ccc;
  cursor: pointer;
}

.add-remove-btn {
  display: inline-block;
  //border-radius: 0.5em;
  background-color: buttonface;
  //border: 0.1em solid #ccc;
  cursor: pointer;
}

.qrcode-text-btn:hover .add-remove-btn:hover {
  background-color: #e9f7f7;
  box-shadow: 0.08em 0.05em 0.08em #ccc;
}

.formContainer {
  width: 50%;
  margin: 5px;
  padding: 10px;
}

.formBodyContainer {
  max-height: 18rem;
  overflow: auto
}

.formTitle {
  font-weight: bold;
  font-size: 150%;
}

/*for playground */
.qrcode-text {
  border: 1px solid;
  padding-right: 1.7em;
  margin-right: 0;
  vertical-align: middle;
}

.qrcode-text + .qrcode-text-btn {
  width: 1.7em;
  margin-left: -1.7em;
  vertical-align: middle;
}

.try {
  color: white;
}

.link-like {
  color: blue;
  cursor: pointer;
  text-decoration: none;
}

.stacking {
  position: relative;
  //z-index: 1;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-even {
  justify-content: space-evenly;
}

.justify-between {
  justify-content: space-between;
}

.ll_hv_c {
  display: flex;
  align-items: center;
}

.media_v_c {
  flex-flow: row;
  @media screen and (max-width: 960px) { /* from landscape tablet on */
    flex-flow: column;
  }
}

.display_inline_for_mobile {
  overflow-y: auto;
  padding-right: 10%;
  padding-left: 10%;
  align-items: flex-start;
  height: 100%;
  @media screen and (max-width: 960px) { /* from landscape tablet on */
    display: inline-grid;
  }
  @media screen and (min-width: 960px) { /* up to landscape tablet */
    padding-top: 3%;
  }
}

.width_responsive {
  width: 40%;
  min-width: 400px;
  @media screen and (max-width: 960px) { /* from landscape tablet on */
    min-width: 90%;
  }
}

.qr-scanner-width_responsive {
  width: 35rem;
  @media screen and (max-width: 960px) { /* from landscape tablet on */
    width: 22rem;
  }
}

.MuiTableRow-head-override {
  height: 30px !important;
}

.table-header-color {
  background-color: #cddae4;
  position: sticky;
  top: 0;
}

.unit_monitoring_btn {
  width: 120px !important;
}

.unit_monitoring_btn_auto_width {
  background-color: white !important;
  width: auto !important;
}

.calendar-class {
  border: none !important;
  width: 310px !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 16px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2F987F;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2F987F;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 16px;
}

.slider.round:before {
  border-radius: 50%;
}

.chattinessWrapper {
  display: flex;
  flex-direction: row;
  row-gap: 10px;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}
.overflow-y-hidden {
  overflow-y: hidden;
}

.table-artifact-data {
  width: 100%;
  border-spacing: 0;
}

$statuses: (
        category: #ffffff00,
        tabletOff: rgba(243, 244, 165, 0.8),
        somOff: rgba(243, 244, 165, 0.8),
        down: rgba(243, 244, 165, 0.8),
        manuallyOff: rgba(196, 195, 242, 0.8),
        preInstall: rgba(153, 240, 243, 0.8),
        prod: white,
        notProd: white,
        total: #D7D7D7,
        live: rgba(194, 244, 199, 0.8),
        error: rgba(255, 198, 147, 0.8)
);

@each $colorname, $color in $statuses {
  .top-table-header-class--#{$colorname} {
    background-color: $color;
    align-items: center;
    justify-content: center;
    display: flex;
    height: 40px;
    border: 1px solid #68678d50;
    box-shadow: 0 0 1px 0 #867979;
    box-sizing: border-box;
    margin-right: 5px;
    font-family: Roboto;
    font-weight: 500;
    font-size: 16px;
    color: rgba(94, 99, 102, 1);
    border-radius: 5px
  }
}

.top-table-cell {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: start;
  height: 40px;
  margin-top: 5px;
  padding-left: 12px;
  cursor: pointer;

  &:hover {
    background-color: rgba(25, 118, 210, 0.15);
  }
}

.top-table-cell-number {
  background-color: rgba(25, 118, 210, 0.04);
  color: #5E6366;
}

.top-table-cell-text {
  background-color: #A3A6A7;
  color: white;
  margin-right: 5px;
}

.show-more {
  font-weight: 500;
  font-size: 16px;
  font-family: Roboto;
  text-decoration: underline;
  display: flex;
  align-items: end;
  width: max-content;
  cursor: pointer;
}

.top-table {
  display: flex;
  max-height: 60vh;
  flex: 1;
  overflow-y: auto;
}

.btn-nice {
  padding: 4px 10px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid rgba(25, 118, 210, 0.5);
  color: #1976d2;
  margin-right: 5px;

  &:hover {
    background-color: rgba(25, 118, 210, 0.04);
    border: 1px solid #1976d2;
  }
}

.btn-nice-true {
  background-color: #1976d2;
  color: white;

  &:hover {
    background-color: #1976d2bb;
  }
}

.clear-filters-false {
  color: #a4a6a7;
  cursor: pointer;
}

.clear-filters-true {
  background-color: #ff785d;
  font-weight: 900;
  cursor: pointer;
}

.btn-home {
  border: 1px solid orange;
  padding: 3px;
  border-radius: 10px;
  margin: 10px;

  &:hover {
    background: orange;
  }
}

.no-wrap {
  white-space: nowrap;
}

.btn-remove-header {
  top: 70px;
  width: 100%;
  height: 50px;

  &.hover {
    background-color: #d1640acc;
  }
}

.btn-activation {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid silver;

  &.blue {
    background-color: #487cff;
    border: unset;
  }
}

.input-drop-down-with-add {
  padding-left: 10px;
  background-color: inherit;
  width: 200px;
  height: 38px;
  font-size: 16px;
  border: none;
}

.removeClick {
  position: absolute;
  right: 8px;
  top: 8px;
  line-height: 40px;
}

.activation-list-header-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.activation-list-search {
  width: 258px;
  height: 38px;
  color: #636363;
  border: 1px solid #CBCBCB;
  margin-right: 10px;
}

.activation-list-filter {
  width: 200px;
  height: 42px;
  margin-right: 20px;
  color: #636363;
}


.my-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  .content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 80%;
    max-width: 400px;
  }

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .close:hover {
    color: red;
  }

  .header {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .body {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .footer {
    display: flex;
    justify-content: flex-end;

    button {
      padding: 10px 20px;
      border: none;
      background-color: #00aaff;
      color: white;
      border-radius: 5px;
      cursor: pointer;

      + button {
        margin-left: 10px;
      }

      &:hover {
        background-color: #0077cc;
      }
    }
  }

  .entry {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;

    input {
      margin-left: 10px;
    }
  }

  .footer button:disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
  }

  .footer button:disabled:hover {
    background-color: #ccc;
  }

  .footer button:disabled + button {
    margin-left: 10px;
  }

  .footer button:disabled + button:hover {
    background-color: #ccc;
  }

}

.overflow-y {
  overflow-y: auto;
}

.page-units-wrapper {
  margin-left: 5px;
  margin-right: 5px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

#unit-info {
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
}

.query-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px;
}
